<template>
    <v-container class="px-16" fluid>
        <v-row justify="center">
            <v-col v-for="(item, index) in stats" :key="index" cols="12" md="3">
                <Material-Stats-Card v-bind="{ ...item }" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import MaterialStatsCard from "../components/base/MaterialStatsCard.vue";
import GeneralApi from "../Api/GeneralApi";

export default {
    components: { MaterialStatsCard },
    data() {
        return {
            stats: [
                {
                    subIcon: "mdi-eye",
                    subText:
                        this.$t("preview") +
                        " " +
                        this.$t("complaints.complaints"),
                    subTextLink: "/Complaints",
                    color: "red",
                    icon: "mdi-badge-account-alert-outline",
                    title: this.$t("dashboardCards.complaints"),
                    value: "0",
                    roles: [24]
                },
                {
                    subIcon: "mdi-eye",
                    subText:
                        this.$t("preview") + " " + this.$t("alerts.alerts"),
                    subTextLink: "/alerts",
                    color: "success",
                    icon: "mdi-account-eye-outline",
                    title: this.$t("dashboardCards.alertsViews"),
                    value: "0",
                    roles: [20]
                },
                {
                    subIcon: "mdi-account-edit-outline",
                    subText: this.$t("employees.ui.changeEmployeeSettings"),
                    subTextLink: "/changeEmployeeSettings",
                    color: "orange",
                    icon: "mdi-cancel",
                    title: this.$t("dashboardCards.bannedEmployees"),
                    value: "0",
                    roles: [0]
                },
                {
                    subIcon: "mdi-eye",
                    subText:
                        this.$t("preview") +
                        " " +
                        this.$t("death.deathAnnouncements"),
                    subTextLink: "/DeathAnnouncements",
                    color: "grey darken-3",
                    icon: "mdi-account-injury-outline",
                    title: this.$t("dashboardCards.deaths"),
                    value: "0",
                    roles: [0]
                }
            ]
        };
    },
    mounted() {
        GeneralApi.DashboardStats().then(response => {
            this.stats[0].value = String(response.data.data.complaintsCount);
            this.stats[1].value = String(response.data.data.alertsViewsCount);
            this.stats[2].value = String(
                response.data.data.bannedEmployeesCount
            );
            this.stats[3].value = String(response.data.data.deathsCount);
        });
    }
};
</script>
<style>
.v-application .text-caption {
    font-family: inherit !important;
}
</style>
